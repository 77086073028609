<template>
    <div>
        <v-app-bar
            :class="`header ${headerClass}`"
            clipped-left
            height="64"
            flat
            app
            >
            <top-bar/>
            <div class="main-menu">
                <router-link :to="{name: 'home'}" class="header__logo">
                    <svg class="logo logo--bg" width="320" height="91"><use xlink:href="#gt-logo-bg" /></svg>
                    <svg class="logo" width="320" height="91"><use xlink:href="#gt-logo" /></svg>
                </router-link>

                <v-spacer></v-spacer>

                <div class="d-none d-md-flex fill-height">
                    <router-link :to="{name: 'home'}" class="main-menu__link fill-height">Home</router-link>
                    <spotlight-dropdown />
                    <router-link :to="{name: 'how-it-works'}" class="main-menu__link">How it Works</router-link>
                    <router-link :to="{name: 'about'}" class="main-menu__link">About Us</router-link>
                    <sign-up-dropdown v-if="!$auth.user()" />
                </div>

                <v-app-bar-nav-icon
                    @click="drawer = true"
                    class="main-menu__toggler d-md-none primary--text mr-0">
                </v-app-bar-nav-icon>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            class="main-menu--aside"
            >
            <v-list>
                <v-list-item-group
                    v-model="group"
                    >
                    <v-list-item>
                        <router-link :to="{name: 'home'}" class="main-menu__link">Home</router-link>
                    </v-list-item>
                    <v-list-item>
                        <spotlight-dropdown />
                    </v-list-item>
                    <v-list-item>
                        <router-link :to="{name: 'how-it-works'}" class="main-menu__link">How it Works</router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link :to="{name: 'about'}" class="main-menu__link">About Us</router-link>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-if="!$auth.user()">
                        <sign-up-dropdown v-if="!$auth.user()" />
                    </v-list-item>
                    <v-list-item v-if="!$auth.user()">
                        <router-link  :to="{name: 'login'}" class="main-menu__link main-menu__link--account">Log In</router-link>
                    </v-list-item>
                    <v-list-item v-if="$auth.user()">
                        <router-link v-if="$auth.user()" :to="{name: dashboardLink}" class="main-menu__link main-menu__link--account">My Dashboard</router-link>
                    </v-list-item>
                    <v-list-item v-if="$auth.user()">
                        <button v-if="$auth.user()" @click="handleLogout" class="main-menu__link main-menu__link--account text-start">
                            Log Out
                        </button>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import TopBar from "./TopBar";
    import SignUpDropdown from "./SignUpDropdown";
    import SpotlightDropdown from "./SpotlightDropdown";

    export default {
        name: "top-menu",
        props: {
            isDashboard: {
                type: Boolean,
                default: false,
            },
            isHomepage: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            appName() {
                return process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "Saas"
            },
            dashboardLink() {
                let role =this.$auth.user().roles[0];
                switch (role) {
                    case 'admin': return 'admin.index'
                    case 'venue': return 'venue.index'
                    case 'artist': return 'artist.index'
                    case 'fan': return 'dashboard.index'
                    default: return 'dashboard.index'
                }
            },
            headerClass() {
                return (this.isHomepage) ? 'header--homepage' : (this.isDashboard) ? 'header--dashboard' : '';
            },
        },
        components: {
            TopBar,
            SignUpDropdown,
            SpotlightDropdown,
            },
        data: () => ({
            drawer: false,
            group: null,
        }),
        methods: {
            handleLogout() {
               this.$auth.logout()
            },
        }
    }
</script>

<style scoped>

</style>
