<template>
    <v-menu open-on-hover tile offset-y>
        <template v-slot:activator="{ on, attrs }">
            <a
                v-bind="attrs"
                v-on="on"
                class="main-menu__link main-menu__link--sign-up"
                >
                Sign Up
            </a>
        </template>
        <v-list subheader>
            <v-list-item :to="{name: 'fans-landing'}" class="main-menu__sub-link">
                <v-list-item-title class="cursor-pointer">Fan</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'artist-landing'}" class="main-menu__sub-link">
                <v-list-item-title class="cursor-pointer">Artist</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'venue-landing'}" class="main-menu__sub-link">
                <v-list-item-title class="cursor-pointer">Venue</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "sign-up-dropdown",
        data: () => {
            return {}
        },
    }
</script>

<style scoped>

</style>
