<template>
    <v-system-bar
        class="top-bar white--text px-6 d-none d-md-flex"
        color="primary" absolute
        >
        <v-spacer></v-spacer>
        <v-btn v-if="!$auth.user()" :to="{name: 'login'}" text plain small color="white">
            Sign In
        </v-btn>
        <v-btn v-if="$auth.user()" :to="{name: dashboardLink}" text plain small color="white">
            My Dashboard
        </v-btn>
        <v-btn v-if="$auth.user()" @click="handleLogout" text plain small color="white">
            Log out
        </v-btn>
    </v-system-bar>
</template>

<script>
    export default {
        name: "top-bar",
        computed: {
            dashboardLink() {
                let role =this.$auth.user().roles[0];
                switch (role) {
                    case 'admin': return 'admin.index'
                    case 'venue': return 'venue.index'
                    case 'artist': return 'artist.index'
                    case 'fan': return 'dashboard.index'
                    default: return 'dashboard.index'
                }
            },
        },
        data: function () {
            return {}
        },
        methods: {
            handleLogout() {
               this.$auth.logout()
            },
        }
    }
</script>

<style scoped>

</style>
