<template>
    <v-menu open-on-hover tile offset-y>
        <template v-slot:activator="{ on, attrs }">
            <a
                v-bind="attrs"
                v-on="on"
                class="main-menu__link"
                >
                Spotlight
            </a>
        </template>
        <v-list subheader>
            <v-list-item :to="{name: 'artists-index'}" class="main-menu__sub-link">
                <v-list-item-title class="cursor-pointer">Featured Artists</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'venues-index'}" class="main-menu__sub-link">
                <v-list-item-title class="cursor-pointer">Featured Venues</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item :to="{name: 'events.index'}">
                <v-list-item-title class="cursor-pointer">Featured Events</v-list-item-title>
            </v-list-item> -->
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "sign-up-dropdown",
        data: () => {
            return {}
        },
    }
</script>

<style scoped>

</style>
